<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p class="description">Hi there! I'm Constantin and I'm an independent Analytics consultant, with a focus on Data Engineering, Business Intelligence and Data Analysis. </p>
    <p> My daily work revolves around solving business problems with data and enabling others to do so. </p>
    <h3>Find me on:</h3>
    <ul>
      <li><a href="https://www.linkedin.com/in/constantin-lungu-668b8756/" target="_blank" rel="noopener">LinkedIn</a></li>
      <li><a href="https://medium.com/@cnstlungu" target="_blank" rel="noopener">Medium</a></li>
      <li><a href="https://datawise.dev" target="_blank" rel="noopener">My blog - Datawise</a></li>
      <li><a href="https://www.notjustsql.com" target="_blank" rel="noopener">Substack</a></li>    </ul>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.description {
  margin-left: auto;
  margin-right: auto;
}
</style>
