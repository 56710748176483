<template>
  <img class="costin" alt="Constantin Lungu" src="./assets/constantin.png">
  <br>
  <img class="achievements" alt="AWS Certified Data Analytics - Specialty" src="./assets/aws-dataanalytics-certification.png">

  <img class="achievements" alt="Google Certified Professional Data Engineer" src="./assets/google-certified-data-engineer.png">
  <Homepage msg="Constantin Lungu"/>
</template>

<script>
import Homepage from './components/Homepage.vue'

export default {
  name: 'App',
  components: {
    Homepage
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.achievements {
  max-width: 125px;
}
</style>
